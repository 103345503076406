<template>
  <div class="rewards-box">
    <CCard color="light">
      <CCardHeader>
        <div>
          <TextScroll :isVis="isVis" :dataList="textArr" @handlerNotice="getAnnouncement"></TextScroll>
        </div>
        <slot name="header" class="bg-h">
          <div class="search-box">
            <div class="sc-item">
              <el-select v-model="atype" style="width:155px">
                <el-option label="RewardIssueFee" value="RewardIssueFee"
                  >RewardIssueFee</el-option
                >
                <el-option label="RewardTopup" value="RewardTopup"
                  >RewardTopup</el-option
                >
                <el-option label="Transfer" value="Transfer"
                  >Transfer</el-option
                >
                <el-option label="Withdraw" value="Withdraw">
                  Withdraw
                </el-option>
              </el-select>
            </div>
            <div class="sc-item">
              <el-date-picker
                v-model="after"
                style="width:190px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('CardDetail.AfterDate')"
              >
              </el-date-picker>
              
            </div>
            <div class="sc-item">
              <el-date-picker
                v-model="before"
                style="width:190px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('CardDetail.BeforeDate')"
              >
              </el-date-picker>
            </div>
            <div class="sc-item">
              <el-button size="small" @click="searchTb" class="sc-btn"
                ><CIcon name="cilMagnifyingGlass"
              /></el-button>
            </div>
            <div class="sc-item">
              <el-button @click="clearData" class="sc-btn" size="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style="height: 16px"
                >
                  <polygon
                    fill="var(--ci-primary-color, currentColor)"
                    points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313"
                    class="ci-primary"
                  />
                </svg>
              </el-button>
            </div>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <el-table :data="rewardDetail" style="width: 100%">
          <template slot="empty">
            <div style="margin: 3rem 0">
              <h2>
                No items
                <CIcon
                  name="cilBan"
                  style="color: #e53535; width: 30px; height: 30px"
                />
              </h2>
            </div>
          </template>
          <el-table-column prop="create_time" :label="$t('RewardCenter.AccountDetail.Time')">
            <!-- <template slot-scope="scope">

            </template> -->
          </el-table-column>
          <el-table-column prop="type" :label="$t('RewardCenter.AccountDetail.Type')"></el-table-column>
          <el-table-column prop="amount" :label="$t('RewardCenter.AccountDetail.Amount')">
            <template slot-scope="scope">
              <div :class="scope.row.amount > 0 ? 'auth-color' : 'ca'">${{ formatNum(scope.row.amount) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('RewardCenter.AccountDetail.Status')">
            <template slot-scope="scope">
              <CBadge :color="getBadge(scope.row.status)">{{
                scope.row.status
              }}</CBadge>
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$t('RewardCenter.AccountDetail.Remark')"></el-table-column>
          <el-table-column
            prop="description"
            :label="$t('RewardCenter.AccountDetail.Description')"
          ></el-table-column>
          <el-table-column prop="source" :label="$t('RewardCenter.AccountDetail.Source')">
            <template slot-scope="scope">
              <div>
                <el-popover placement="left" width="266" trigger="click">
                  <div class="sor-box">

                    <el-descriptions
                     v-loading="sourceLoading"
                      class="margin-top"
                      :title="$t('RewardCenter.AccountDetail.Source')"
                      :column="1"
                      direction="horizontal"
                    >
                      <el-descriptions-item :span="2" :label="$t('RewardCenter.AccountDetail.User')"
                        >{{ source.user_name }} </el-descriptions-item
                      >
                      <el-descriptions-item :span="2" :label="$t('RewardCenter.AccountDetail.CardNumber')"
                        >{{ source.card_number }}</el-descriptions-item
                      >
                      <el-descriptions-item :label="$t('RewardCenter.AccountDetail.Type')"
                        >{{ source.type }}</el-descriptions-item
                      >
                      <el-descriptions-item :label="$t('RewardCenter.AccountDetail.Amount')"
                        ><span :class="source.amount > 0 ? 'auth-color' : 'ca'">${{ source.amount }}</span></el-descriptions-item
                      >
                    </el-descriptions>
                  </div>
                  <el-button :disabled="scope.row.type !== 'RewardIssueFee' && scope.row.type !== 'RewardTopup' ? true : false" slot="reference" style="background:transparent;border-color: transparent;">
                      <i
                    style="cursor: pointer"
                    @click="handerSource(scope.row)"
                    class="el-icon-search"
                  ></i>
                  </el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </CCardBody>
      <CCardFooter>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageTotal"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="20"
          @current-change="handerPage"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getPageNotice} from "../../api/http"
import TextScroll from "../../components/TextScroll.vue"
export default {
  components: {TextScroll},
  data() {
    return {
      items: [
        {
          create_time: "2020-02-32 12:32:00",
          amount: "20",
          type: "Tranfer",
          remark: "test11",
          description: "test",
          source: "",
          status: "Complete",
          ref_id: '0'
        },
        {
          create_time: "2020-02-32 12:32:00",
          amount: "20",
          type: "Tranfer",
          remark: "test222",
          description: "test",
          source: "",
          status: "Complete",
          ref_id: 1
        },
      ],
      before: "",
      after: "",
      atype: "",
      disabledSource: false,
      textArr: [
        // {
        //   id:1,
        //   val:'代理中心-账户明细'
        // }
      ],
      isVis: false
    };
  },
  created() {
    // 初始化
    this.$store.dispatch("getAccDetails")
  },
  methods: {
    // 请求通告数据
    getAnnouncement(noticeType) {
      let obj = {
        uid: window.localStorage.getItem("uid"),
        type: "get_page_notice",
        // page: "fund_fund"
        page: noticeType
      }
      getPageNotice(obj).then((res) => {
        console.log("notice==>", res)
        if(res.code == 200) {
          if(res.data.length !== 0) {
            this.isVis = true
            this.textArr = res.data
          }else {
            this.isVis = false
          }
        }
      })
    },
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    handerPage(e) {
        // this.searchTb(e)
        let parms = {
        type: "get_agent_transaction",
        uid: window.localStorage.getItem("uid"),
        before: "",
        after: "",
        atype: "",
        page: e,
        page_size: 20,
      };
      this.$store.dispatch("getAccDetails", parms);
    },
    handleSizeChange(e) {
      console.log("eee==>", e);
    },
    clearData() {
      this.before = "";
      this.after = "";
      this.atype = "";
    },
    // 状态颜色
    getBadge(Status) {
      return Status === "Complete"
        ? "success"
        : Status === "Pending"
        ? "info"
        : Status === "Frozen"
        ? "danger"
        : Status === "Cancel"
        ? "dark"
        : "success";
    },
    // 筛选
    searchTb() {
      let parms = {
        type: "get_agent_transaction",
        uid: window.localStorage.getItem("uid"),
        before: this.before,
        after: this.after,
        atype: this.atype,
        page: 1,
        page_size: 20,
      };
      this.$store.dispatch("getAccDetails", parms);
    },
    // 获取来源
    handerSource(e) {
      console.log("来源===>", e);
        this.$store.commit("setSourceLoading", true)
        this.$store.dispatch("getSourceData", e.ref_id);
    },
  },
  computed: {
    ...mapState(["rewardDetail", "source", "pageTotal", "sourceLoading"]),
  },
};
</script>

<style scoped lang="scss">
.auth-color {
  color: #00bea4;
  font-weight: bold;
}
.ca{
  color: #FF1111;
  font-weight: bold;
}
.rewards-box {
  padding-left: 2.6rem;
  height: 100vh;
  background: #fff;
  .search-box {
    display: flex;
    .sc-item {
      padding: 0.5rem 0.5rem;
      .sc-btn {
        width: 66px;
        padding: 0.45rem 0;
        background: #00bea4;
        color: #fff;
        border-radius: 20px;
        outline: 0;
        &:nth-child(1) {
          background: #fff;
          color: #888;
        }
      }
    }
  }
}
</style>